/* eslint-disable */
import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="" style={{textAlign:"center",width:"100%",overflow:"hidden"}}>
     <div style={{marginTop:250,fontSize:40,fontWeight:100,width:"50%",textAlign:"left",marginLeft:"auto",marginRight:"auto",display:"block",overflow:"hidden"}}>
       Hi there, I'm Rajvinder (Robin) Singh
       <br/>
       <br/>

       I'm a technology leader, builder and investor focussed on the Artificial Intelligence / Machine Learning space.
       <br/>
       <br/>


        <br/>
        <br/>


        <div style={{width:"100%",textAlign:"center"}}>
          <img src="/logo.png" style={{height:400}}/>
        </div>

     </div>
    </div>
  );
}

export default App;
